<template>
  <!-- begin:: Header -->
  <div
    id="kt_header"
    ref="kt_header"
    class="header header-fixed d-print-none"
    v-bind:class="headerClasses"
  >
    <div
      class="d-flex align-items-stretch justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div
        class="d-none d-lg-flex align-items-center mr-3"
        style="width: 100vw; justify-content: space-between; padding: 0 5vw"
      >
        <div class="header-logo">
          <router-link to="/">
            <img alt="Logo" :src="headerLogo" class="logo-sticky max-h-35px" />
          </router-link>
        </div>

        <!--begin::Buttons for manager web-->
        <div v-if="isAuthenticated">
          <!--begin::All-my-chats button-->
          <span
            v-if="currentUserRoleID !== rolesList.ID_SUPERADMIN"
            :title="$t('CHATS.TITLE')"
            class="mr-12"
          >
            <inline-svg
              v-if="AlertCountChat === 0"
              src="/media/icons/chat.svg"
              class="cursor-pointer"
              @click.native="goToChats"
            />
            <v-badge
              v-else
              overlap
              class="cursor-pointer"
              color="bg-danger text-white"
              @click.native="goToChats"
            >
              <template v-slot:badge>
                {{ AlertCountChat }}
              </template>
              <inline-svg src="/media/icons/chat.svg" />
            </v-badge>
          </span>
          <!--end::All-my-chats button-->

          <!--begin::All-my-unfinished-incidents button-->
          <span
            v-if="currentUserRoleID !== rolesList.ID_SUPERADMIN"
            :title="$t('INCIDENTS.TITLE')"
          >
            <inline-svg
              v-if="AlertCountIncident === 0"
              src="/media/icons/alerts-alt-2.svg"
              class="cursor-pointer"
              @click.native="goToIncidents"
            />
            <v-badge
              v-else
              overlap
              class="cursor-pointer"
              color="bg-danger text-white"
              @click.native="goToIncidents"
            >
              <template v-slot:badge>
                {{ AlertCountIncident }}
              </template>
              <inline-svg src="/media/icons/alerts-alt-2.svg" />
            </v-badge>
          </span>
          <!--end::All-my-unfinished-incidents button-->
        </div>
        <!--end::Buttons for manager web-->

        <!--begin::Buttons for landing-->
        <div v-else>
          <span
            class="mr-3 cursor-pointer"
            :class="
              $router.currentRoute.path.substring(0, 12) === '/pricesearch'
                ? 'text-tertiary font-weight-boldest'
                : 'text-white'
            "
            @click="startNewPriceSearch"
          >
            {{ $t("HEADER.SEARCH") }}
          </span>

          <router-link to="/sender">
            <span
              class="mr-3"
              :class="
                $router.currentRoute.path === '/sender'
                  ? 'text-tertiary font-weight-boldest'
                  : 'text-white'
              "
            >
              {{ $t("HEADER.SENDER") }}
            </span>
          </router-link>

          <router-link to="/transporter">
            <span
              class="mr-3"
              :class="
                $router.currentRoute.path === '/transporter'
                  ? 'text-tertiary font-weight-boldest'
                  : 'text-white'
              "
            >
              {{ $t("HEADER.TRANSPORTER") }}
            </span>
          </router-link>

          <router-link to="/contact">
            <span
              class="mr-3"
              :class="
                $router.currentRoute.path === '/contact'
                  ? 'text-tertiary font-weight-boldest'
                  : 'text-white'
              "
            >
              {{ $t("HEADER.CONTACT") }}
            </span>
          </router-link>
        </div>
        <!--end::Buttons for landing-->

        <div class="d-none d-lg-flex flex-row">
          <KTTopbar></KTTopbar>

          <!--begin::Aside Toggle-->
          <button
            v-if="isAuthenticated"
            class="btn btn-icon aside-toggle ml-18"
            id="kt_aside_desktop_toggle"
            ref="kt_aside_desktop_toggle"
          >
            <span class="svg-icon svg-icon-xxl">
              <inline-svg src="/media/icons/menu-alt-3.svg" />
            </span>
          </button>
          <div v-else>
            <LanguageSelector :compact-layout="true" class="ml-18 mr-6" />
            <button
              class="btn btn-pill btn-clean py-1 px-12"
              style="font-size: 14px; min-width: 0"
              @click="goToLogin"
            >
              <span class="svg-icon svg-icon-xl">
                <inline-svg src="/media/icons/perfil.svg" />
              </span>
              {{ $t("AUTH.LOGIN.DO_LOGIN") }}
            </button>
          </div>
          <!--end::Aside Toggle-->
        </div>
      </div>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
import { mapGetters } from "vuex";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import LanguageSelector from "@/view/content/LanguageSelector.vue";

import PriceSearchService from "@/core/services/api/v2/pricesearch.service";

export default {
  name: "KTHeader",
  components: {
    KTTopbar,
    LanguageSelector
  },

  props: {
    AlertCountChat: {
      type: Number,
      required: false,
      default: 0
    },
    AlertCountIncident: {
      type: Number,
      required: false,
      default: 0
    }
  },

  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");
  },

  methods: {
    goToLogin() {
      this.$router.push({ name: "Login" });
    },

    goToChats() {
      this.$router.push({ name: "Chats" });
    },

    goToIncidents() {
      this.$router.push({ name: "Incidents" });
    },

    startNewPriceSearch() {
      PriceSearchService.cleanCachedLists();
      this.$router.push("/pricesearch");
    }
  },

  computed: {
    ...mapGetters([
      "isAuthenticated",
      "currentUserRoleID",
      "rolesList",
      "layoutConfig",
      "getClasses"
    ]),

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      return process.env.BASE_URL + this.layoutConfig("self.logo.sticky");
    },

    /**
     * Check if header container is fluid
     */
    widthFluid() {
      return this.layoutConfig("header.self.width") === "fluid";
    }
  }
};
</script>
