<template>
  <!-- begin::AsideMenu for SenderAdmin & SenderEmployee Roles -->
  <div
    v-if="
      isAuthenticated &&
        (currentUserRoleID === rolesList.ID_SENDER_ADMIN ||
          currentUserRoleID === rolesList.ID_SENDER_EMPLOYEE)
    "
    class="d-flex flex-column justify-content-between"
    style="height: 90vh;"
  >
    <div>
      <ul class="menu-nav py-9">
        <li class="menu-item">
          <CurrentUserTag
            :compact-layout="false"
            :include-company="true"
            class="menu-link"
            style="cursor: default"
          />
        </li>
        <li class="menu-item pt-3 pl-6">
          <button
            class="btn btn-pill button-form btn-tertiary px-6 py-2"
            @click="startNewPriceSearch"
            style="width: fit-content !important"
          >
            <inline-svg src="/media/icons/tarifas.svg" class="mr-3" />
            {{ $t("PRICE_SEARCH.GO_TO_SEARCH_1") }}
          </button>
        </li>
      </ul>

      <ul class="menu-nav" v-for="(submenu, s) in menuItemsList" :key="s">
        <li class="menu-item submenuTitle">
          <span class="text-white pl-9">
            {{ submenu.title }}
          </span>
        </li>

        <router-link
          v-for="(item, i) in submenu.lines"
          :key="i"
          :to="item.to"
          v-slot="{ href, navigate, isActive, isExactActive }"
          custom
        >
          <li
            v-if="!item.requiresPremiumSubscription || loggedUserIsPremium"
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <inline-svg class="menu-icon mr-4" :src="item.icon" />
              <span class="menu-text font-weight-bolder">
                {{ item.label }}
              </span>
            </a>
          </li>
        </router-link>
      </ul>

      <MenuLogout />
    </div>
  </div>
  <!-- end::AsideMenu for SenderAdmin & SenderEmployee Roles -->
</template>

<style scoped>
.submenuTitle {
  font-size: 12px;
  margin-bottom: 6px;
  text-transform: uppercase;
  border-bottom: 1px solid #828ba2;
}
.submenuTitle > span {
  padding-bottom: 6px;
}
</style>

<script>
import { mapGetters } from "vuex";
import CurrentUserTag from "@/view/content/CurrentUserTag.vue";

import PriceSearchService from "@/core/services/api/v2/pricesearch.service";
import MenuLogout from "@/view/layout/aside/MenuLogout.vue";

export default {
  name: "MenuSender",
  components: {
    CurrentUserTag,
    MenuLogout
  },

  computed: {
    ...mapGetters([
      "isAuthenticated",
      "currentUserRoleID",
      "rolesList",
      "currentUserCompanyIsPremium"
    ])
  },

  data() {
    return {
      loggedUserRoleID: 0,
      loggedUserIsPremium: false,
      menuItemsList: [
        {
          title: this.$i18n.t("MENU.LOGISTICS"),
          lines: [
            {
              to: "/manager/myprofile",
              label: this.$i18n.t("MY_PROFILE.TITLE"),
              icon: "/media/icons/perfil.svg",
              requiresPremiumSubscription: false
            },
            {
              to: "/manager/invoicecenters",
              label: this.$i18n.t("MENU.INVOICE_CENTERS"),
              icon: "/media/icons/facturacion.svg",
              requiresPremiumSubscription: true
            },
            {
              to: "/manager/addresses",
              label: this.$i18n.t("MENU.FREQUENT_ADDRESSES"),
              icon: "/media/icons/pin-2.svg",
              requiresPremiumSubscription: true
            },
            {
              to: "/manager/myvehicles",
              label: this.$i18n.t("MENU.MY_VEHICLES"),
              icon: "/media/icons/car-copy.svg",
              requiresPremiumSubscription: true
            }
          ]
        },
        {
          title: this.$i18n.t("MENU.SHIPMENTS_MANAGEMENT"),
          lines: [
            {
              to: "/manager/offers",
              label: this.$i18n.t("MENU.OFFERS"),
              icon: "/media/icons/ofertas.svg",
              requiresPremiumSubscription: false
            },
            {
              to: "/manager/shipments",
              label: this.$i18n.t("MENU.SHIPMENTS"),
              icon: "/media/icons/envios.svg",
              requiresPremiumSubscription: false
            },
            {
              to: "/manager/incidents",
              label: this.$i18n.t("MENU.INCIDENTS"),
              icon: "/media/icons/alerts-alt-2.svg",
              requiresPremiumSubscription: false
            },
            {
              to: "/manager/chats",
              label: this.$i18n.t("MENU.CONVERSATIONS"),
              icon: "/media/icons/chat.svg",
              requiresPremiumSubscription: false
            }
          ]
        }
      ]
    };
  },

  mounted() {
    this.loggedUserRoleID = this.currentUserRoleID;
    this.loggedUserIsPremium = this.currentUserCompanyIsPremium;

    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "setCompany") {
        this.loggedUserRoleID = state.auth.user.Company.RoleID;
        this.loggedUserIsPremium = state.auth.user.Company.IsPremium;
      }
    });
  },

  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    startNewPriceSearch() {
      PriceSearchService.cleanCachedLists();
      this.$router.push("/pricesearch");
    }
  }
};
</script>
