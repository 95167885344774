<template>
  <!-- begin::LanguageSelector -->
  <select
    v-model="languageSelected"
    class="form-control bg-dark text-white border-white rounded-pill"
    :class="[compactLayout ? 'compact' : '']"
    @change="onChangeLanguageSelector"
  >
    <option
      v-for="(item, i) in languageSelectorOptions"
      :key="i"
      :value="item.lang"
      class="bg-dark text-white"
    >
      {{ item.lang.toUpperCase() }}
      {{ compactLayout ? "" : " - " + item.name }}
    </option>
  </select>
  <!-- end::LanguageSelector -->
</template>

<style scoped>
select {
  width: auto;
  min-width: 12em;
  background-image: url(/media/icons/chevron-down.svg);
  background-repeat: no-repeat;
  background-position: 95% center;
}
select.compact {
  border: 0 !important;
  min-width: 5em !important;
  display: inline !important;
}
</style>

<script>
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "language-selector",
  props: {
    compactLayout: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      languageSelectorOptions: i18nService.languages,
      languageSelected: i18nService.getActiveLanguage()
    };
  },
  methods: {
    onChangeLanguageSelector() {
      i18nService.setActiveLanguage(this.languageSelected);
      window.location.reload();
    }
  }
};
</script>
