<template>
  <!-- begin:: Footer -->
  <div class="footer kt-grid__item bg-dark pt-9 d-print-none" id="kt_footer">
    <!--begin::Container1-->
    <div
      class="d-flex align-items-stretch justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div
        class="d-none d-lg-flex align-items-top"
        style="width: 100vw; padding: 0 8vw"
      >
        <!--begin::Col-->
        <div class="col-lg-3 pl-1 pr-6 my-lg-0 my-5">
          <inline-svg src="/media/logos/logo.svg" class="h-48px" />
          <LanguageSelector :compact-layout="false" class="mt-9" />
        </div>
        <!--end::Col-->

        <!--begin::Col-->
        <div class="col-lg-3 px-6 my-lg-0 my-5">
          <h5 class="text-white font-weight-bolder pb-3 opacity-70 pl-12">
            {{ $t("FOOTER.CONTACT") }}
          </h5>
          <p class="m-0 text-white">
            <span class="svg-icon svg-icon-2x svg-icon-white mr-3">
              <inline-svg src="/media/icons/phone.svg" />
            </span>
            <span>+34 687 896 342</span>
          </p>
          <p class="my-2 text-white">
            <span class="svg-icon svg-icon-2x svg-icon-white mr-3">
              <inline-svg src="/media/icons/contact.svg" />
            </span>
            <a href="mailto:info@logiok.es" class="text-white">
              info@logiok.es
            </a>
          </p>
          <p class="m-0 text-white d-flex align-items-top">
            <span class="svg-icon svg-icon-2x svg-icon-white mr-3">
              <inline-svg src="/media/icons/pin-1.svg" />
            </span>
            <a
              href="https://goo.gl/maps/EJHyZDgwDoaNpdvz8"
              target="_blank"
              class="text-white"
            >
              Rambla Catalunya, 124
              <br />
              Planta 3 Puerta 1
              <br />
              Barcelona 08008
            </a>
          </p>
        </div>
        <!--end::Col-->

        <!--begin::Col-->
        <div class="col-lg-3 px-6 my-lg-0 my-5">
          <h5 class="text-white font-weight-bolder pb-3 opacity-70">
            {{ $t("FOOTER.COMPANY") }}
          </h5>
          <div class="d-flex align-items-center">
            <div class="d-flex flex-column">
              <router-link to="/sender">
                <p class="text-white">
                  {{ $t("FOOTER.HOW_IT_WORKS") }}
                </p>
              </router-link>
              <router-link to="/transporter">
                <p class="text-white">
                  {{ $t("FOOTER.TRANSPORTER") }}
                </p>
              </router-link>
              <router-link to="/contact">
                <p class="text-white ">
                  {{ $t("FOOTER.CONTACT_US") }}
                </p>
              </router-link>
            </div>
          </div>
        </div>
        <!--end::Col-->

        <!--begin::Col-->
        <div class="col-lg-3 px-6 my-lg-0 my-5">
          <h5 class="text-white font-weight-bolder pb-3 opacity-70">
            {{ $t("FOOTER.FOLLOW_US") }}
          </h5>
          <div class="d-flex align-items-center">
            <div class="d-flex flex-row">
              <a
                href="https://www.linkedin.com/company/logiok-4-0"
                target="_blank"
                class="text-white"
              >
                <inline-svg
                  src="/media/icons/linkedin.svg"
                  class="social-icon"
                />
              </a>
              <a
                href="https://www.instagram.com/logiok4.0"
                target="_blank"
                class="text-white mx-4"
              >
                <inline-svg
                  src="/media/icons/instagram.svg"
                  class="social-icon"
                />
              </a>
              <a
                href="https://www.facebook.com/Logiok-100134762598441"
                target="_blank"
                class="text-white mr-4"
              >
                <inline-svg src="/media/icons/fb.svg" class="social-icon" />
              </a>
              <a
                href="https://twitter.com/LogiOK"
                target="_blank"
                class="text-white"
              >
                <inline-svg src="/media/icons/twitter.svg" class="social-icon" />
              </a>
            </div>
          </div>
          <h5 class="text-white font-weight-bolder pt-8 pb-3 opacity-70">
            {{ $t("FOOTER.SECURE_PAYMENT") }}
          </h5>
          <div class="d-flex align-items-center">
            <div class="d-flex flex-row">
              <inline-svg src="/media/icons/visa.svg" class="card-icon" />
              <img src="/media/icons/mastercard.jpg" class="card-icon ml-6" />
            </div>
          </div>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
    </div>
    <!--end::Container1-->

    <!--begin::Separator-->
    <div class="separator separator-solid opacity-7 mt-6 mb-3"></div>
    <!--end::Separator-->

    <!--begin::Container2-->
    <div
      class="py-3 d-flex align-items-center justify-content-between flex-lg-row flex-column"
      style="width: 100vw; padding: 0 8vw"
    >
      <!--begin::Nav-->
      <div class="d-flex align-items-center order-lg-2 order-1 mb-lg-0 mb-5">
        <router-link to="/legal/privacy">
          <span class="text-white pl-6">
            {{ $t("FOOTER.PRIVACY_POLICY") }}
          </span>
        </router-link>
        <router-link to="/legal/cookies">
          <span class="text-white px-9">
            {{ $t("FOOTER.COOKIES") }}
          </span>
        </router-link>
        <router-link to="/legal/terms">
          <span class="text-white pr-0">
            {{ $t("FOOTER.TERMS") }}
          </span>
        </router-link>
      </div>
      <!--end::Nav-->
    </div>
    <!--end::Container2-->

    <CookieLaw
      class="bg-primary"
      :buttonText="$t('FOOTER.COOKIES_BANNER_BUTTON')"
      buttonClass="btn btn-pill button-form btn-tertiary"
    >
      <div slot="message">
        <p class="text-white mb-0">
          {{ $t("FOOTER.COOKIES_BANNER_1") }}
        </p>
        <router-link to="/legal/cookies">
          <p class="text-white mb-0">
            <u>{{ $t("FOOTER.COOKIES_BANNER_2") }}</u>
          </p>
        </router-link>
      </div>
    </CookieLaw>
  </div>
  <!-- end:: Footer -->
</template>

<style scoped>
.social-icon {
  width: 40px !important;
  height: 40px !important;
  border: 1px solid white;
  border-radius: 50%;
  padding: 6px;
}
.card-icon {
  width: 42px !important;
  height: auto !important;
  border-radius: 15%;
}
</style>

<script>
import { mapGetters } from "vuex";
import CookieLaw from "vue-cookie-law";

import LanguageSelector from "@/view/content/LanguageSelector.vue";

export default {
  name: "KTFooter",
  components: {
    CookieLaw,
    LanguageSelector
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    }
  }
};
</script>
