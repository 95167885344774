<template>
  <!-- begin::AsideMenu for Superadmin Role -->
  <div
    v-if="isAuthenticated && loggedUserRoleID === rolesList.ID_SUPERADMIN"
    class="d-flex flex-column justify-content-between"
    style="min-height: 90vh;"
  >
    <div>
      <ul class="menu-nav py-9" style="border-bottom: 1px solid #828ba2">
        <li class="menu-item">
          <CurrentUserTag
            :compact-layout="false"
            :include-company="false"
            class="menu-link"
            style="cursor: default"
          />
        </li>
      </ul>

      <ul class="menu-nav">
        <router-link
          v-for="(item, i) in menuItemsList"
          :key="i"
          :to="item.to"
          v-slot="{ href, navigate, isActive, isExactActive }"
          custom
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <inline-svg class="menu-icon mr-4" :src="item.icon" />
              <span class="menu-text font-weight-bolder">
                {{ item.label }}
              </span>
            </a>
          </li>
        </router-link>
      </ul>

      <MenuLogout />
    </div>
  </div>
  <!-- end::AsideMenu for Superadmin Role -->
</template>

<script>
import { mapGetters } from "vuex";
import CurrentUserTag from "@/view/content/CurrentUserTag.vue";
import MenuLogout from "@/view/layout/aside/MenuLogout.vue";

export default {
  name: "MenuSuperadmin",

  components: {
    CurrentUserTag,
    MenuLogout
  },

  computed: {
    ...mapGetters(["isAuthenticated", "currentUserRoleID", "rolesList"])
  },

  data() {
    return {
      loggedUserRoleID: 0,
      menuItemsList: [
        {
          to: "/manager/myprofile",
          label: this.$i18n.t("MY_PROFILE.TITLE"),
          icon: "/media/icons/perfil.svg"
        },
        {
          to: "/manager/senders",
          label: this.$i18n.t("MENU.SENDERS"),
          icon: "/media/icons/remi.svg"
        },
        {
          to: "/manager/transporters",
          label: this.$i18n.t("MENU.TRANSPORTERS"),
          icon: "/media/icons/transportista.svg"
        },
        {
          to: "/manager/users",
          label: this.$i18n.t("MENU.USERS"),
          icon: "/media/icons/usuarios.svg"
        },
        {
          to: "/manager/shipmentsall",
          label: this.$i18n.t("MENU.SHIPMENTS"),
          icon: "/media/icons/envios.svg"
        },
        {
          to: "/manager/subscriptions/types",
          label: this.$i18n.t("MENU.SUBSCRIPTION_TYPES"),
          icon: "/media/icons/subscripcion.svg"
        },
        {
          to: "/manager/vehicles",
          label: this.$i18n.t("MENU.VEHICLES"),
          icon: "/media/icons/car-copy.svg"
        }
      ]
    };
  },

  mounted() {
    this.loggedUserRoleID = this.currentUserRoleID;

    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "setCompany") {
        this.loggedUserRoleID = state.auth.user.Company.RoleID;
      }
    });
  },

  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
