<template>
  <!-- begin::AsideMenu for TransporterAdmin Role -->
  <div
    v-if="
      isAuthenticated && loggedUserRoleID === rolesList.ID_TRANSPORTER_ADMIN
    "
    class="d-flex flex-column justify-content-between"
    style="min-height: 90vh;"
  >
    <div>
      <ul class="menu-nav py-9">
        <li class="menu-item">
          <CurrentUserTag
            :compact-layout="false"
            :include-company="true"
            class="menu-link"
            style="cursor: default"
          />
        </li>
      </ul>

      <ul class="menu-nav" v-for="(submenu, s) in menuItemsList" :key="s">
        <li class="menu-item submenuTitle">
          <span class="text-white pl-9">
            {{ submenu.title }}
          </span>
        </li>

        <router-link
          v-for="(item, i) in submenu.lines"
          :key="i"
          :to="item.to"
          v-slot="{ href, navigate, isActive, isExactActive }"
          custom
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <inline-svg class="menu-icon mr-4" :src="item.icon" />
              <span class="menu-text font-weight-bolder">
                {{ item.label }}
              </span>
            </a>
          </li>
        </router-link>
      </ul>

      <MenuLogout />
    </div>
  </div>
  <!-- end::AsideMenu for TransporterAdmin Role -->
</template>

<style scoped>
.submenuTitle {
  font-size: 12px;
  margin-bottom: 6px;
  text-transform: uppercase;
  border-bottom: 1px solid #828ba2;
}
.submenuTitle > span {
  padding-bottom: 6px;
}
</style>

<script>
import { mapGetters } from "vuex";
import CurrentUserTag from "@/view/content/CurrentUserTag.vue";
import MenuLogout from "@/view/layout/aside/MenuLogout.vue";

export default {
  name: "MenuTransporter",
  components: {
    CurrentUserTag,
    MenuLogout
  },

  computed: {
    ...mapGetters(["isAuthenticated", "currentUserRoleID", "rolesList"])
  },

  data() {
    return {
      loggedUserRoleID: 0,
      menuItemsList: [
        {
          title: this.$i18n.t("MENU.LOGISTICS"),
          lines: [
            {
              to: "/manager/myprofile",
              label: this.$i18n.t("MY_PROFILE.TITLE"),
              icon: "/media/icons/perfil.svg"
            },
            {
              to: "/manager/fares",
              label: this.$i18n.t("MENU.FARES"),
              icon: "/media/icons/tarifas.svg"
            },
            {
              to: "/manager/services",
              label: this.$i18n.t("MENU.SERVICES"),
              icon: "/media/icons/servicios-extra.svg"
            },
            {
              to: "/manager/trucks",
              label: this.$i18n.t("MENU.FLEET"),
              icon: "/media/icons/truck.svg"
            },
            {
              to: "/manager/drivers",
              label: this.$i18n.t("MENU.DRIVERS"),
              icon: "/media/icons/choferes.svg"
            }
          ]
        },
        {
          title: this.$i18n.t("MENU.SHIPMENTS_MANAGEMENT"),
          lines: [
            {
              to: "/manager/offers",
              label: this.$i18n.t("MENU.OFFERS_RECEIVED"),
              icon: "/media/icons/ofertas.svg"
            },
            {
              to: "/manager/shipments",
              label: this.$i18n.t("MENU.SHIPMENTS"),
              icon: "/media/icons/envios.svg"
            },
            {
              to: "/manager/incidents",
              label: this.$i18n.t("MENU.INCIDENTS"),
              icon: "/media/icons/alerts-alt-2.svg"
            },
            {
              to: "/manager/chats",
              label: this.$i18n.t("MENU.CONVERSATIONS"),
              icon: "/media/icons/chat.svg"
            }
          ]
        }
      ]
    };
  },

  mounted() {
    this.loggedUserRoleID = this.currentUserRoleID;

    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "setCompany") {
        this.loggedUserRoleID = state.auth.user.Company.RoleID;
      }
    });
  },

  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
