var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.isAuthenticated && _vm.loggedUserRoleID === _vm.rolesList.ID_TRANSPORTER_ADMIN
  )?_c('div',{staticClass:"d-flex flex-column justify-content-between",staticStyle:{"min-height":"90vh"}},[_c('div',[_c('ul',{staticClass:"menu-nav py-9"},[_c('li',{staticClass:"menu-item"},[_c('CurrentUserTag',{staticClass:"menu-link",staticStyle:{"cursor":"default"},attrs:{"compact-layout":false,"include-company":true}})],1)]),_vm._l((_vm.menuItemsList),function(submenu,s){return _c('ul',{key:s,staticClass:"menu-nav"},[_c('li',{staticClass:"menu-item submenuTitle"},[_c('span',{staticClass:"text-white pl-9"},[_vm._v(" "+_vm._s(submenu.title)+" ")])]),_vm._l((submenu.lines),function(item,i){return _c('router-link',{key:i,attrs:{"to":item.to,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var href = ref.href;
  var navigate = ref.navigate;
  var isActive = ref.isActive;
  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('inline-svg',{staticClass:"menu-icon mr-4",attrs:{"src":item.icon}}),_c('span',{staticClass:"menu-text font-weight-bolder"},[_vm._v(" "+_vm._s(item.label)+" ")])],1)])]}}],null,true)})})],2)}),_c('MenuLogout')],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }