<template>
  <!-- begin::AsideMenu Logout button -->
  <ul v-if="isAuthenticated" class="menu-nav">
    <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item">
      <button class="menu-link" @click="onLogout">
        <inline-svg class="menu-icon mr-3" src="/media/icons/power-button.svg" />
        <span class="menu-text">{{ $t("MENU.LOGOUT") }}</span>
      </button>
    </li>
  </ul>
  <!-- end::AsideMenu Logout button -->
</template>

<script>
import { mapGetters } from "vuex";
import { UNDO_AUTH } from "@/core/services/store/auth.module";
import LoginService from "@/core/services/api/v2/login.service";

export default {
  name: "MenuLogout",

  computed: {
    ...mapGetters(["isAuthenticated"])
  },

  methods: {
    onLogout() {
      this.loggedUserRoleID = 0;
      LoginService.logout().then(() => {
        this.$store.dispatch(UNDO_AUTH);
        this.$router.push({ name: "Landing" });

        this.$nextTick(() => {
          this.$vuetify.goTo(0);
        });
      });
    }
  }
};
</script>
