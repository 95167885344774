<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--begin: User Bar -->
    <div class="topbar-item">
      <CurrentUserTag :compact-layout="true" />
    </div>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<script>
import CurrentUserTag from "@/view/content/CurrentUserTag.vue";

export default {
  name: "KTTopbar",
  components: {
    CurrentUserTag
  }
};
</script>
