<template>
  <!-- begin::AsideMenu -->
  <div
    v-if="isAuthenticated"
    class="d-flex flex-column justify-content-between"
    style="height: 90vh; min-height: 700px;"
  >
    <MenuObserver v-if="loggedUserRoleID === this.rolesList.ID_OBSERVER" />

    <MenuSuperadmin v-if="loggedUserRoleID === this.rolesList.ID_SUPERADMIN" />

    <MenuSender
      v-if="
        loggedUserRoleID === this.rolesList.ID_SENDER_ADMIN ||
          loggedUserRoleID === this.rolesList.ID_SENDER_EMPLOYEE
      "
    />

    <MenuTransporter
      v-if="loggedUserRoleID === this.rolesList.ID_TRANSPORTER_ADMIN"
    />
  </div>
  <!-- end::AsideMenu -->
</template>

<script>
import { mapGetters } from "vuex";
import MenuObserver from "@/view/layout/aside/MenuObserver.vue";
import MenuSuperadmin from "@/view/layout/aside/MenuSuperadmin.vue";
import MenuSender from "@/view/layout/aside/MenuSender.vue";
import MenuTransporter from "@/view/layout/aside/MenuTransporter.vue";

export default {
  name: "AsideMenu",
  components: {
    MenuObserver,
    MenuSuperadmin,
    MenuSender,
    MenuTransporter
  },
  data() {
    return {
      loggedUserRoleID: 0
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated", "currentUserRoleID", "rolesList"])
  },
  mounted() {
    this.loggedUserRoleID = this.currentUserRoleID;

    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "setCompany") {
        this.loggedUserRoleID = state.auth.user.Company.RoleID;
      }
    });
  }
};
</script>
