<template>
  <!-- begin::CurrentUser -->
  <div class="d-flex flex-row align-items-center" v-if="isAuthenticated">
    <v-avatar
      class="myAvatar mr-4"
      :class="[
        compactLayout ? 'h-36px w-36px min-h-36px min-w-36px' : 'h-48px w-48px'
      ]"
    >
      <v-img
        :src="
          loggedUser.Avatar && loggedUser.Avatar.length > 0
            ? loggedUser.Avatar
            : defaultAvatar
        "
      />
    </v-avatar>
    <span
      class="myName"
      :class="[compactLayout ? 'myNameCompact' : 'myNameLarge']"
    >
      {{ loggedUser.Name }}
      <br v-if="includeCompany" />
      <span v-if="includeCompany" class="myCompany">
        {{ loggedUser.Company }}
      </span>
    </span>
  </div>
  <!-- end::CurrentUser -->
</template>

<style scoped>
.myAvatar {
  border: 1px solid #fff;
}

.myName {
  color: #fff;
  font-size: 16px !important;
}
.myNameCompact {
  line-height: 21px;
}
.myNameLarge {
  line-height: 24px;
  font-weight: bold;
}
.myCompany {
  font-weight: 400;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: "current-user-tag",
  props: {
    compactLayout: {
      type: Boolean,
      required: false,
      default: false
    },
    includeCompany: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      loggedUser: {
        Name: "",
        Avatar: "",
        Company: ""
      },
      defaultAvatar: "/media/users/blank.png"
    };
  },
  mounted() {
    this.loggedUser.Name = this.currentUserName;
    this.loggedUser.Avatar = this.currentUserAvatar
      ? this.currentUserAvatar
      : this.defaultAvatar;
    this.loggedUser.Company = this.currentUserCompanyName;

    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "setName") {
        this.loggedUser.Name = state.auth.user.Company.Name;
      } else if (mutation.type === "setAvatar") {
        this.loggedUser.Avatar = state.auth.user.Company.Avatar;
      } else if (mutation.type === "setCompany") {
        this.loggedUser.Name = state.auth.user.Company.Name;
        this.loggedUser.Avatar = state.auth.user.Company.Avatar;
        this.loggedUser.Company = state.auth.user.Company.CompanyName;
      }
    });
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "currentUserName",
      "currentUserAvatar",
      "currentUserCompanyName"
    ])
  }
};
</script>
