<template>
  <div
    id="kt_header_mobile"
    class="header-mobile align-items-center d-print-none"
    v-bind:class="headerClasses"
  >
    <!--begin::Logo-->
    <a href="/">
      <img alt="Logo" :src="headerLogo" class="logo-default max-h-30px" />
    </a>
    <!--end::Logo-->

    <!--begin::Toolbar-->
    <div
      v-if="isAuthenticated"
      class="d-flex align-items-center justify-content-between"
    >
      <!--begin::All-my-chats button-->
      <span
        v-if="isAuthenticated && currentUserRoleID !== rolesList.ID_SUPERADMIN"
        :title="$t('CHATS.TITLE')"
      >
        <inline-svg
          v-if="AlertCountChat === 0"
          src="/media/icons/chat.svg"
          class="cursor-pointer"
          @click.native="goToChats"
        />
        <v-badge
          v-else
          overlap
          class="cursor-pointer"
          color="bg-danger text-white"
          @click.native="goToChats"
        >
          <template v-slot:badge>
            {{ AlertCountChat }}
          </template>
          <inline-svg src="/media/icons/chat.svg" />
        </v-badge>
      </span>
      <!--end::All-my-chats button-->

      <!--begin::All-my-unfinished-incidents button-->
      <div
        v-if="isAuthenticated && currentUserRoleID !== rolesList.ID_SUPERADMIN"
        :title="$t('INCIDENTS.TITLE')"
        class="ml-6"
      >
        <inline-svg
          v-if="AlertCountIncident === 0"
          src="/media/icons/alerts-alt-2.svg"
          class="cursor-pointer"
          @click.native="goToIncidents"
        />
        <v-badge
          v-else
          overlap
          class="cursor-pointer"
          color="bg-danger text-white"
          @click.native="goToIncidents"
        >
          <template v-slot:badge>
            {{ AlertCountIncident }}
          </template>
          <inline-svg src="/media/icons/alerts-alt-2.svg" />
        </v-badge>
      </div>
      <!--end::All-my-unfinished-incidents button-->

      <!--begin::Header Menu Mobile Toggle-->
      <button
        class="p-0 burger-icon ml-6 mr-3"
        id="kt_aside_tablet_and_mobile_toggle"
        ref="kt_aside_tablet_and_mobile_toggle"
      >
        <span>
          <inline-svg src="/media/icons/menu-alt-3.svg" />
        </span>
      </button>
      <!--end::Header Menu Mobile Toggle-->
    </div>
    <!--end::Toolbar-->

    <div v-else>
      <LanguageSelector :compact-layout="true" class="ml-3 mr-3" />
      <button
        class="btn btn-pill btn-clean py-1 px-6 ml-6"
        style="font-size: 14px; min-width: 0"
        @click="goToLogin"
      >
        <span class="svg-icon svg-icon-xxl">
          <inline-svg src="/media/icons/perfil.svg" />
        </span>
        {{ $t("AUTH.LOGIN.DO_LOGIN") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTLayoutHeaderTopbar from "@/assets/js/layout/base/header-topbar.js";
import LanguageSelector from "@/view/content/LanguageSelector.vue";

export default {
  name: "KTHeaderMobile",
  components: {
    LanguageSelector
  },

  props: {
    AlertCountChat: {
      type: Number,
      required: false,
      default: 0
    },
    AlertCountIncident: {
      type: Number,
      required: false,
      default: 0
    }
  },

  mounted() {
    // Init Header Topbar For Mobile Mode
    KTLayoutHeaderTopbar.init(this.$refs["kt_header_mobile_topbar_toggle"]);
  },

  computed: {
    ...mapGetters([
      "isAuthenticated",
      "currentUserRoleID",
      "rolesList",
      "layoutConfig",
      "getClasses"
    ]),

    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      return process.env.BASE_URL + this.layoutConfig("self.logo.sticky");
    },

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header_mobile");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    }
  },

  methods: {
    goToLogin() {
      this.$router.push({ name: "Login" });
    },

    goToChats() {
      this.$router.push({ name: "Chats" });
    },

    goToIncidents() {
      this.$router.push({ name: "Incidents" });
    }
  }
};
</script>
